.postscript-first {
  .section-row {
    .section-inner.banner-full-width {
      max-width: 2000px;
      position: relative;
      .card-banner-image {
        line-height: 0;
        height: 593px;
        @media (max-width: 940px) {
          height: auto;
        }
        img {
          height: 593px !important;
          max-width: none;
          position: absolute;
          left: calc(50% - 940px);
          @media (max-width:940px) {
            position: relative;
            height: auto !important;
            left: calc(50% - 470px);
          }
        }
      }
      .card-banner-description {
        max-width: 940px;
        margin: 0 auto;
        position: relative;
        height: 631px;
        margin-top: -631px;
        @media (max-width:940px) {
          height: auto;
          margin-top: 0;
        }
        .banner-description {
          position: absolute;
          bottom: 0;
          background-color: #F0F0F0;
          padding: 40px 50px;
          font-family: $base-font-family;
          @media (max-width:940px) {
            position: relative;
          }
          h5, h6 {
            font-family: $base-font-family;
            font-weight: normal;
          }
          h5 {
            font-size: 1.5em;
          }
          h6 {
            font-size: 1.125em;
          }
        }
      }
    }
  }
  .section-row.banner-row {
    padding: 0;
  }
}
