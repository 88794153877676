.node-type-resource {
  .content-additional {
    h2 {
      .view-title {
        background: $title-prefix no-repeat 0 100% / 38px auto,
                    $title-suffix no-repeat 100% 0 / 38px auto;
      }
    }
  }
}
