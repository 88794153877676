@mixin card-hidden-summary {
	.section-row:not(.row-max-1) {
		@media (min-width:540px) {
			.view-links .card {
				.card-text.card-with-summary {
					height: 100%;
					width: 100%;

					.card-summary-wrapper {
						min-height: 100%;
						width: 100%;
						opacity:0;
						height: 0;
						position: relative;
						overflow: hidden;
						transition: none;

					}
				}
				.card-image + .card-with-summary {
					position: relative;
				}

				&:hover {
					.card-text.card-with-summary {
						background: $card-text-with-summary-hover-bg !important;
						color: $card-text-with-summary-text-color !important;
						text-overflow: ellipsis;
						width: 100%;
						
							.card-title,
							.card-title-et,
							.card-title-field {
								color: $card-text-with-summary-title-color !important;
							}

						
						.card-summary-wrapper {
							opacity:1;
							height: auto;
							transition: all 0.375s ease-in;
						}
					}

					.card-image +.card-text.card-with-summary,
					.card-logo +.card-text.card-with-summary {
						position: absolute;
						bottom: 0;
					}
				}
			}
		}
	}
}
