// // /* DEFAULT CARD LAYOUT	*/
// .card {
// 	border:$card-border;
// 	border-radius: $card-border-radius;
// 	box-shadow:$card-shadow;
// 	margin-bottom:$card-margin-bottom;
	
// 	transition: $card-transition;

// 	&.slick-slide {
// 		margin-top: 10px;
// 	}
// 	.card-title,
// 	.card-title-et,
// 	.card-title-field {
// 		font-family:$card-title-font-family;
// 	}

// 	h3 {
// 		display:block;
// 		font:$card-title-font;
// 		margin-bottom: 0.5em;
// 		letter-spacing: 0.03rem;
		
// 		.card-title-field,
// 		.card-title-et,
// 		.card-title {
// 			display:block;
// 			font:$card-title-font;
// 			margin-bottom: $vert-space/2;
// 		}
// 	}	
// 	.card-text {
// 		padding: $card-text-padding;
// 	}
// }
// .card-date,
// .card-display-date,
// .card-location,
// .card-author,
// .card-authors,
// .card-contributor,
// .card-members {
// 	color:$card-date-text-color;
// 	font:$card-date-font;
// 	margin: 0 0 0.5rem;
// }
// .card-display-date {
// 	background: $card-date-bg-color;
// 	border-bottom:$card-date-border;
// 	border-radius:$card-date-border-radius;
// }
// .card-location,
// .card-contributor,
// .card-members {
// 	font-weight:500;
// }
// .card-author {
// 	word-wrap: break-word;
// 	&:before {
// 		content: "by ";
// 	}
// }
// .card-label {
// 	font-weight:bold;
// }
// .card-summary {
// 	color: $trolley_grey;
// 	font-size: 1.125rem;
// 	margin-bottom: 0.5em;
// }
// .card-date-display-override {
// 	font-size: 0.875em;
// }

// .view-links .card {
// 	.card-title,
// 	.card-title-et,
// 	.card-title-field {
// 		text-transform: uppercase;
// 		text-align: center;
// 	}
// }
// // /* MORE LINK */
// .section-row:not(.view-type-slider) //, .side-row:not(.view-type-slider) /* to target sidebar more links separately */ 
// 	{
// 	.card-more-link {
// 		display:table !important;
// 		margin-left:2.35765%;
// 		text-align:center;

// 		@media (min-width:0) and (max-width:719px) {
// 			margin-left:0;
// 			width:100%;
// 			//display: block;
// 		}

// 		a.card {
// 			box-sizing:border-box;
// 			display:table-cell;
// 			font:$card-more-link-font;
// 			padding:$vert-space $horz-space;
// 			vertical-align:middle;
// 			width:100%;

// 			@media (min-width:0) and (max-width:719px) {
// 				display: block;
// 			}

// 			&:after {
// 				content:" ";
// 				display:inline-block;
// 				height:16px;
// 				margin:0 0 -2px 10px;
// 				width:14px;
// 			}
// 		}
// 	}

// 	&.row-max-1 {
// 		.card-more-link,
// 		.card-last-link-url {
// 			margin-left: 0;
// 			float: right;
// 		}
// 	}
// }

// // /* FEED LINK */
// .card-feed-link {
// 	clear: both;
// 	display: block;
// 	float: right;
// 	font:$card-feed-link-font;
// 	overflow: hidden;
// 	a {
// 		padding: $vert-space/2 $horz-space;
// 		border-radius: 40px;
// 		display: block;
// 		font-weight: normal;
// 		transition: $card-transition;
			
// 		&:after {
// 			content: "";
// 			display: inline-block;
// 			width: 18px;
// 			height: 18px;
// 			//border-radius: 50%;
// 			/*margin-left: 10px;*/
// 			vertical-align: bottom;
// 		}
// 	}
// }



// // /* INNER CARD ELEMENTS */
// .tweet .card-text {
// 	display:block;
// 	font:normal 1.125em/1.22222222222222 $base-font-family;
// }

// .card-logo {
// 	background:#FFFFFF;
// }
// .card-video {
// 	margin-bottom:15px;
	
// 	span.ext {
// 		display:none;
// 	}
// }

// .card-tags {
// 	display: inline-block;
// 	font-size: 0.875em;
// 	font-weight: 600;

// 	ul {
// 		display: inline-block;
// 		li {
// 			float: left;
// 			margin-right: 10px;
// 			&:after {
// 				content:"|";
// 				display: inline-block;
// 				float: right;
// 				margin-left: 10px;
// 			}
// 			&:last-child {
// 				&:after {
// 					content:"";
// 				}
// 			}					
// 		}
// 	}
// }

// .card-citation {
// 	font-style:italic;
// }
.card-date,
.card-location {
	font-size:1em;
	// line-height:1.57142857142857;
}
// .card-label {
// 	font-weight:bold;
// }
// .card-date-display-override {
// 	font-size: 0.875em;
// }
// .card-resource-type,
// .card-content-type {
// 	display:inline-block;
// 	font-weight: 400;
// 	font-size: 0.875em;
// }
// .card-journal {
// 	display: inline-block;
// 	font-weight: 400;
// 	font-size: 0.875em;
// 	&:after {
// 		content:"|";
// 		margin: 0 0px 0 2px;	
// 	}
// }
// .card-email {
// 	font-weight: 600;
// }

// .card-job-title {
// 	font-size:1em;
// 	line-height:1.375;
// }

// .card-footer {
// 	display: block;
// 	line-height: 1.125em;
// }

// @media (min-width:$narrow) and (max-width:959px) {
// 	.card-with-summary {
// 		.card-summary {
// 			font-size: 0.875rem;
// 			line-height: 1.42857142857143;
// 		}
// 	}
// }
// //Display box-link card-summary on hover 
// @include card-hidden-summary;

// .card-type,
// .card-start-date {
// 	box-sizing: border-box;
// 	display: block;
// 	font: 500 1.125rem/25px $title-font-family;
// 	height: 35px;
// 	margin: 0 $horz-space*1.5 0;
// 	position: relative;
// 	text-align: center;
// 	text-transform: uppercase;
// }
// .card-start-date {
// 	background: $platinum_blue;
// 	clear: both;
// 	color: $primary;
// 	padding: 5px 5px;
// 	width: 82px;
// }
// .card-type {
// 	@include clearfix;
// 	float: left;
// 	padding: 5px 20px;
	
// 	&.card-type-event {
// 		background: $weldon_blue;
// 		color: white;
// 		width: 82px;
// 	}
// 	&.card-type-blog,
// 	&.card-type-news {
// 		background: $platinum;
// 		color: $primary ;
// 	}
// 	&.card-type-resource,
// 	&.card-type-resource_series {
// 		background: $independence;
// 		color: white;
// 	}
// 	&.card-type-update {
// 		background: $sea_blue;
// 		color: white;
// 	}
// }
// .card-image + .card-type,
// .card-image-et + .card-type,
// .card-image-outer + .card-type,
// .card-cover-image + .card-type,
// .card-full-width-image + .card-type,
// .card-picture + .card-type,
// .card-image-URL + .card-type {
// 	margin-top: -35px;
// }
// .card-type + .card-text {
// 	clear: both;
// }
// .list .views-row {
// 	@include media ($narrow) {
// 		.card-image,
// 		.card-photo,
// 		.card-logo,
// 		.card-cover-image,
// 		.card-image-et,
// 		.card-full-width-image,
// 		.card-image-URL + .card-type {
// 			@include span-columns(4);
// 			margin-right: 0;
// 			margin-bottom: 30px;
// 		}
// 		.card-type {
// 			float: none;
// 			display: inline-block;
// 		}
// 		.card-type+.card-text {clear: right;}

// 		.card-image + .card-type,
// 		.card-image-et + .card-type,
// 		.card-image-outer + .card-type,
// 		.card-cover-image+.card-type,
// 		.card-full-width-image + .card-type,
// 		.card-picture + .card-type {
// 			float: left;
// 			margin-left: calc(-31.76157% + 30px);
// 			margin-top: 0;
// 		}
// 	}
// }
// .card-about-me-12 {
// 	font-size: 1rem;
// 	line-height: 1.1875;
	
// 	:last-child {
// 		margin-bottom: 0;
// 	}
// }
// .card-job-title-4,
// .card-department-5 {
// 	font-size: 1.125rem;
// 	font-weight: 500;
// 	line-height: 1.16666666666667;
// 	margin-bottom: 0.5rem;
// }
// .card-job-title-4 + .card-department-5 {
// 	&:before {
// 		content: ", ";
// 		margin-left: -3px;
// 	}
// }

// Events date groupings
main h3.event-date-wrapper.views-row {
	background-color: transparent;
	color: $portal_secondary;
	@media(min-width:540px) {
		background-color: $portal_secondary;
		color: white;
	}
	.event-date {
		border-color: $portal_secondary;
		@media(min-width:539px) {
			border-color: white;
		}
	}
}

main .view-events.list {
	h3.event-date-wrapper.views-row {
		background-color: transparent;
		color: $portal_secondary;
		.event-date {
			border-color: $portal_secondary;
		}
	}
}
