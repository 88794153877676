.body-expand {
	.card-body-text-expand {
		span a.morelink {
			background-color: $portal_primary;
			&:hover {
				background-color: $black_olive;
			}
		}
		span a.morelink.less {
			background-color: $portal_primary;
			&:hover {
				background-color: $black_olive;
			}
		}
	}
}