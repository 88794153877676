.i18n-en .site-title a { background:$portal-logo-en no-repeat scroll 0 0 transparent; }

#site-header {
	background-color:$header-bg-color;
	@media (max-width:879px) {
		border-bottom: 8px solid $nav-base-bg-color;
	}
	.preheader-outer-wrapper {
		background:$platinum;
		
		.preheader-inner-wrapper {
			@include outer-container($site-max-width);
		}
		
		.hosted-by {
			clear: both;
			font-size: 0.875rem;
			line-height: 1.142857142857143;
			overflow: hidden;
			padding: 6px 0;
			text-align: right;
			vertical-align: middle;
			
			.preheader-logo {
				background:$logo-en no-repeat scroll 0 0 / auto 20px transparent;
				display: block;
				float: right;
				font-size: 0;
				height: 21px;
				margin: 0 10px 0 8px;
				text-indent: -9999em;
				width: 97px;
				
				&:after {
					background: $home-icon no-repeat scroll 0 2px / auto 13px transparent;
					content: " ";
					display: inline-block;
					height: 21px;
					margin: 0 0 0 3px;
					width: 13px;
				}
				
				&:hover {
					&:after {
					background: $home-icon-over no-repeat scroll 0 2px / auto 13px transparent;			
					}
				}
			}
		}
	}
	.site-branding {
		margin-top:1.5rem;
		margin-left:1.25rem;
		position: relative;
		@include media(880px) {
			top:0;
		}
		.site-title {
			position:absolute;
			left:0;
			top: -20px;
			@media (min-width:879px) {
				top: 0;
			}
			a {
				background-size:contain;
				display:block;
				text-indent:-999em;
				overflow:hidden;
				width:356px;
				height:94px;
				
				@media (max-width:879px) {
					height: 80px;
				}
			}
		}
		.site-name {
			display: none;
			position: absolute;
			left: 165px;
			top: 10px;
			background: url("../images/Urban humanitarian response.svg") no-repeat scroll 0 0 rgba(0,0,0,0);
			background-size: 150px;
			width: 150px;
			height: 29px;
			@media(min-width: 400px) {
				display:block
			}
			@media(min-width: 768px) {
				left:285px;
				top: 20px;
				width: 200px;
				height: 49px;
				background-size: contain
			}
			@media(min-width: 768px)and (max-width: 990px) {
				top:-10px
			}
		}
		.site-slogan {
			position:absolute;
			left:235px;
			top:2.5rem;
		}
	}
	.search-toggle-wrapper {
    position: absolute;
    top: 0;
    right: 0;
		display:none;

		@include media(880px) {
			display: block;
			
			.search-toggle {
				height: 46px;
				width: 75px;
				/*position: absolute;
				top: -46px;
				top: 0px;
				right: 0;*/
				text-indent: -9999px;
				background: transparent $search-icon-white no-repeat scroll 50% 50%;

				@media (min-width:880px) and (max-width:1150px) {
					height: 39px;
					top: -39px;
				}

				&:hover {
					background: $nav-hover-bg-color $search-icon-hover no-repeat scroll 50% 50%;
					border-bottom: $nav-hover-boder;
					cursor: pointer;
				}

				&.main {
					background: $nav-hover-bg-color $close-icon no-repeat scroll 50% 50%;
					border-bottom: $nav-highlight-boder;

					&:hover {
						background: $nav-hover-bg-color $close-icon-hover-grey no-repeat scroll 50% 50%;
						border-bottom: $nav-hover-boder;
					}
				}
			}
		}
	}
	.block-search {
		display:none;
		
		
		@include media(880px) {
			display: block;
			position: absolute;
			right: 0;
		}
		
		#search-block-form {
			display:none;
			
			@include media(880px) {
				position: absolute;
				right: 0;
				top: 46px;
				background: $header-search-bg-color;
				z-index: 1;
				padding: 10px;
			}

			h2 {
				display:none;
			}
			.form-item-search-block-form {
				margin: 0;
				float:left;
				
				input.form-text {
					width:26rem;
					height:2.5rem;
					background-color:$header-search-field-bg-color;
					color:$header-search-field-text-color;
					box-shadow:$header-search-field-box-shadow;
					border:$header-search-field-border;
					border-radius:$header-search-field-border-radius;
					font:$header-search-field-font;
					padding:0.375rem 0.75rem;
					margin: 0;
					font-size: 1rem;
				}
			}
			.form-actions {
				float:left;
				margin:0;
				input.form-submit {
					background: transparent $search-icon no-repeat scroll 0 0;
					background-size:18px;
					width:18px;
					height:18px;
					text-indent:-999em;
					overflow:hidden;
					padding:0;
					position:absolute;
					right:1.25rem;
					top:1.25rem;
					
					&:hover {
						background: transparent $search-icon-hover no-repeat scroll 0 0;
						cursor: pointer;
					}
				}
			}
		}
	}
	.block-menu {
		ul.menu {
			a {
//				font:$header-menu-font;
				color:white;
				background-color:$header-menu-link-color;
//				padding: 8px 20px;
//				border-radius: 20px;
//				outline: none;

				&:hover {
					color:white;
					background-color:$header-menu-link-hover;
				}
				
				&:before {
					vertical-align: text-top;
				}
			}
			
			a[href^="/user/logout"] {
				color:white;
				background-color:$card-links-action-bg;
				
				&:hover {
					color:white;
					background-color:$card-links-hover-bg; 
				}
			}
		}
	}
	
	.block-gtranslate {
		float:right;
		margin-top:1.25rem;
		margin-right:0.5rem;
		
		@include media(880px) {
			margin-bottom:3.5rem;
		}
		
		select {
			border-radius: 20px;
			background: $arrow-button-down-alt no-repeat 100% 50% / 35px auto $header-search-bg-color;
			border: none;
			font-size: 14px;
			color: #40525F;
			-webkit-appearance: none;
			padding: 0 40px 0 20px;
			height: 35px;
			margin: 0;			
			outline: none;
			
			@media (max-width:879px) {
				max-width: 170px;
			}
		}
	}
	
	.view-header-sections {
		display: none;
		
		// @include media(880px) {
		@include media(1240px) {
			display: block;
		}
		float:right;
		margin-top:1rem;
		margin-right:0.5rem;
		
		.section-row {
			padding: 0;
		}
		
		.card-follow-us-on ul li { display: inline-block; }

		.card-links {
			display: inline-block;
			margin-right: 10px;
			text-indent: -9999px;
		}
	}
	.block-locale {
		clear:both;
		display:none;
	}
}
