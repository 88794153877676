@import url('https://fonts.googleapis.com/css?family=Oswald:400,500,700|Roboto:400,500,700');

$base-font-family      			: 'Roboto',"Open Sans", "Segoe UI", Tahoma, sans-serif;
$title-font-family    			: 'Oswald', Impact, "Franklin Gothic Bold", sans-serif;
$subtitle-font-family 		 	: 'Oswald', Impact, "Franklin Gothic Bold", sans-serif;


$base-font-size							: 16px;
$base-line-height						: 1.5;

$base-font-size-normal			: 18px;

$header-menu-font 					: 0.875rem/1 $base-font-family;
$header-search-field-font 	: 0.875rem/1.286 $base-font-family;

$nav-base-font        		 	: 1.25rem/1.3 $title-font-family;
$nav-base-font-smaller 			: /*1rem*/ 1.250em/1.375 $title-font-family;
$nav-sub-base-font        	: 1rem/1.3 $base-font-family;

$breadcrumb-font 						: /*1rem*/ 500 0.889rem/1 $base-font-family;

$section-row-title-font 		: 500 2.375rem/1.342105263157895 $title-font-family; // /* Nugget title */

$side-row-title-font 				: 1.25rem/1.3 $title-font-family; // /* Sidebar nugget title */

$card-title-font-family			: $title-font-family;
$card-title-font        		: 500 1.5rem/1.33333333333333 $card-title-font-family;
$card-font-family       		: $base-font-family;
$card-more-link-font				: normal 1.125rem/1 $base-font-family;
$card-feed-link-font				: 500 0.8125rem/1.46153846153846 $base-font-family;

$button-link-font 					: 600 $base-font-size/1 $base-font-family;

/* FORMS */
$button-font 								: 600 $base-font-size/1 $base-font-family;


/* FORMS */
$footer-title-font					: 500 1.75rem/1.357142857142857 $title-font-family;

// /* TABLES */
$table-header-font					: 500 1.375rem/1.33333333333333 $title-font-family;
$table-caption-font			 		: 1.125em/1.33333333333333 $title-font-family;
$table-cell-font			 			: 1.125em/1.33333333333333 $base-font-family ;

