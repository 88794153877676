// Font styles for the following can be configured in var-typography

// /* HEADER */ //
$header-bg-color                   : white;

$header-menu-link-color            : $portal_primary;
$header-menu-link-hover            : $black_olive;

$header-search-bg-color      			 : $platinum;
$header-search-field-bg-color      : white;
$header-search-field-text-color    : $secondary;
$header-search-field-box-shadow    : none;
$header-search-field-border        : none;
$header-search-field-border-radius : 0;


// /* MAIN MENU */ //
$nav-wrapper-bg-color	  						: $black_olive;
$nav-base-bg-color        					: $black_olive;
$nav-base-text-color      					: white;
$nav-base-padding         					: 0.625rem 1.5625rem;
$nav-base-hover-padding   					: 0.625rem 1.5625rem 0.450rem;
$nav-sub-base-padding								: 0.625rem 1.125rem;
$nav-sub-base-hover-padding					: 0.625rem 1.125rem 0.450rem;

$nav-base-padding-smaller 					: 0.375rem 1.125rem; //for use at awkward breakpoints so the menu still fits on one line
$nav-base-hover-padding-smaller 		: 0.375rem 1.125rem 0.200rem;

$nav-active-bg-color      : $portal_primary;
$nav-active-text-color    : white;
$nav-active-border-radius : 0;

$nav-hover-bg-color       : $platinum;
$nav-sub-hover-bg-color    : $white_smoke;
$nav-hover-text-color     : $portal_primary;
$nav-hover-boder	  	    : 0.175rem solid $portal_primary;

$nav-highlight-bg-color   : $platinum;
$nav-highlight-text-color : $primary;
$nav-highlight-boder	    : 0.175rem solid white;

$nav-button-bg-color      : $trolley_grey;
 
$nav-sub-bg-color         : $platinum;
$nav-sub-text-color       : $primary;
$nav-sub-box-shadow       : $box-shadow;
$nav-sub-border           : none;
$nav-sub-border-radius    : none;
$nav-sub-2nd-bg-color     : $white_smoke;
$nav-sub-3rd-bg-color     : $baby_powder;
$nav-mob-border						: 1px solid $lavender_gray;
$nav-mob-border-hover			: 1px solid white;


// /* BREADCRUMBS */ //
$breadcrumb-bg-color     : white;
$breadcrumb-action-color : $sea_blue;
$breadcrumb-hover-color  : $portal_primary;
$breadcrumb-active-color : $nickel;
$breadcrumb-div-color    : $black_olive;


// /* PRIMARY TABS */ //
$tab-active-bg-color     : $platinum;
$tab-active-text-color   : $nav-highlight-text-color;
$tab-active-border       : $primary-border;
$tab-inactive-bg-color   : $base-background-color;
$tab-inactive-text-color : $primary;
$tab-hover-bg-color      : $nav-hover-bg-color;
$tab-hover-text-color    : $nav-hover-text-color;
$tab-border              : 1px solid $nav-highlight-bg-color;
$tab-border-bottom       : $primary-border;



// /* CARDS */ //
// /* --- CARD DEFAULT SETTINGS */
$card-shadow                   : none; /*$box-shadow;*/
$card-border                   : 0;
$card-border-radius            : 0 0 20px 0;
$card-text-padding             : $vert-space $horz-space*1.5 $vert-space*1.5 !default;
$card-margin-bottom            : $vert-space*1.5 !default;
$card-transition               : background-color 300ms linear 0s;


// /* --- EVENT DISPLAY DATES
$card-date-bg-color            : transparent;
$card-date-border              : none; //1px solid $pale_grey
$card-date-border-radius       : 0;
$card-date-text-color          : $secondary;
$card-date-font                : 1rem/1.1875 $base-font-family;


// /* --- HOVER STYLES: SAME FOR ALL CARDS */ 
$card-hover-bg-color           : inherit;
$card-hover-border             : none;
$card-hover-shadow             : none; /*$box-shadow;*/
$card-action-hover             : $portal_primary; //colour of card title text when you hover over it
$card-text-hover-color         : $secondary; //colour of regular card text when you hover over it
$card-date-hover-text-color    : inherit; /*lighten($trolley_grey,6%);*/
$card-date-hover-bg-color      : transparent; /*$pale_grey;*/ // allows display dates (e.g. events) to have different hover background from the rest of the card

/*
// --- CARD SUMMARIES ON HOVER
// ----- Card summary can be hidden with the card-hidden-summary mixin.
// ----- un/comment the appropriate line in base/_cards.scss as appropriate
// ----- currently, this applies to box links only, to extend, adjust the mixin in mixins/_card-hidden-summary.scss 
*/
$card-text-hover-bg                 : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-hover-bg    : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-title-color : $portal_primary; // alternative background for summary text revealed on hover
$card-text-with-summary-text-color  : $secondary; // alternative background for summary text revealed on hover


// /* --- CARD-LIGHT - light coloured background */
$card-light-bg-color           : white;
$card-light-border-color       : transparent;
$card-light-title-color        : $black_olive;
$card-light-action-default     : $sea_blue;
$card-light-action-hover       : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-light-text-color         : $secondary;
$card-light-subtitle-color     : $secondary;

// /* --- CARD-MEDIUM - medium coloured background */
$card-medium-bg-color          : $isabelline;
$card-medium-border-color      : transparent;
$card-medium-title-color       : $black_olive;
$card-medium-action-default    : $black_olive;
$card-medium-action-hover      : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-medium-text-color        : $secondary;
$card-medium-subtitle-color    : $secondary;

// /* --- CARD-DARK - dark coloured background */
$card-dark-bg-color            : $platinum;
$card-dark-border-color        : transparent;
$card-dark-title-color         : $black_olive;
$card-dark-action-default      : $black_olive;
$card-dark-action-hover        : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-dark-text-color          : $secondary;
$card-dark-subtitle-color      : $secondary;

// /* --- CARD-HIGHLIGHT - alternative coloured background to make cards "pop pop" */
$card-highlight-bg-color       : $black_olive;
$card-highlight-border-color   : transparent;
$card-highlight-title-color    : white;
$card-highlight-action-default : white;
$card-highlight-action-hover   : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-highlight-text-color     : white;
$card-highlight-subtitle-color : white;


// /* --- CARD-MORE-LINKS */
$card-more-link-default-bg          : $portal_primary;
$card-more-link-default-color       : white;
$card-more-link-default-icon        : $arrow-white-right no-repeat 100% 100% / 14px auto;
$card-more-link-default-hover-bg    : $black_olive;
$card-more-link-default-hover-color : white;
$card-more-link-default-hover-icon  : $arrow-white-right no-repeat 100% 100% / 14px auto;

$card-more-link-alt-bg              : $portal_secondary;
$card-more-link-alt-color           : white;
$card-more-link-alt-icon            : $arrow-white-right no-repeat 100% 100% / 14px auto;
$card-more-link-alt-hover-bg        : $black_olive;
$card-more-link-alt-hover-color     : white;
$card-more-link-alt-hover-icon      : $arrow-white-right no-repeat 100% 100% / 14px auto;



// /* --- CARD-FEED-LINKS */
$card-feed-link-default-bg          : $portal_primary; // background for the entire link
$card-feed-link-default-color       : white;
$card-feed-link-default-icon        : transparent $arrow-white-right no-repeat scroll 50% 50% / 7px 10px; //background for the default :after component
$card-feed-link-default-hover-bg    : $black_olive;
$card-feed-link-default-hover-color : white;
$card-feed-link-default-hover-icon  : transparent $arrow-white-right no-repeat scroll 50% 50% / 7px 10px;

$card-feed-link-alt-bg              : $portal_secondary; // background for the entire link
$card-feed-link-alt-color           : white;
$card-feed-link-alt-icon            : transparent $arrow-blue-right no-repeat scroll 50% 50% / 7px 10px; //background for the alternative :after component
$card-feed-link-alt-hover-bg        : $black_olive;
$card-feed-link-alt-hover-color     : white;
$card-feed-link-alt-hover-icon      : transparent $arrow-white-right no-repeat scroll 50% 50% / 7px 10px;


// /* --- BUTTON LINKS   */
$button-link-default-bg                 : $portal_primary;
$button-link-default-border-color       : $portal_primary;
$button-link-default-color              : white;
$button-link-default-icon               : none;
$button-link-default-hover-bg           : $black_olive;
$button-link-default-hover-border-color : $black_olive;
$button-link-default-hover-color        : none;
$button-link-default-hover-icon         : none;

$button-link-alt-bg                     : $black_olive;
$button-link-alt-border-color           : $black_olive;
$button-link-alt-color                  : white;
$button-link-alt-icon                   : none;
$button-link-alt-hover-bg               : $portal_primary;
$button-link-alt-hover-border-color     : $portal_primary;
$button-link-alt-hover-color            : none;
$button-link-alt-hover-icon             : none;




// /* MAIN PAGE */ //
$hero-bg              : $trolley_grey;  // /* Should not be the same as $main-page-bg or $palette-default-bg-color for banded sites */
$main-page-bg         : $isabelline;
$section-main-padding : $vert-space*1.5 0 !default;




// /* SLICK CONTROLS PALETTE */
$slick-default-nav-bg              : transparent; // used on slide and galler views
$slick-default-controls-bg         : none; // used on twitter views etc
$slick-default-prev-bg             : $portal_primary $arrow-white-left no-repeat scroll 50% 50%;
$slick-default-prev-hover-bg       : $black_olive $arrow-white-left no-repeat scroll 50% 50%;
$slick-default-next-bg             : $portal_primary $arrow-white-right no-repeat scroll 50% 50%;
$slick-default-next-hover-bg       : $black_olive $arrow-white-right no-repeat scroll 50% 50%;
$slick-default-dot-border          : 2px solid $weldon_blue;
$slick-default-dot-hover-bg				 : $black_olive;
$slick-default-dot-hover-border    : 2px solid $black_olive;
$slick-default-dot-active-bg-color : $portal_primary;
$slick-default-dot-active-border   : 2px solid $portal_primary;

$slick-alt-1-nav-bg                : rgba(255,255,255,0.25); // used on slide and galler views
$slick-alt-1-controls-bg           : none; // used on twitter views etc
$slick-alt-1-prev-bg               : white $arrow-dark-left no-repeat scroll 50% 50%;
$slick-alt-1-prev-hover-bg         : $sea_blue $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-1-next-bg               : white $arrow-dark-right no-repeat scroll 50% 50%;
$slick-alt-1-next-hover-bg         : $sea_blue $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-1-dot-default-border    : 2px solid white;
$slick-alt-1-dot-hover-bg					 : $sea_blue;
$slick-alt-1-dot-hover-border      : 2px solid $sea_blue;
$slick-alt-1-dot-active-bg-color   : white;
$slick-alt-1-dot-active-border     : 2px solid white;

$slick-alt-2-nav-bg                : $isabelline; // used on slide and galler views
$slick-alt-2-controls-bg           : none; // used on twitter views etc
$slick-alt-2-prev-bg               : $sea_blue $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-2-prev-hover-bg         : $independence $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-2-next-bg               : $sea_blue $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-2-next-hover-bg         : $independence $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-2-dot-default-border    : 2px solid $weldon_blue;
$slick-alt-2-dot-hover-bg					 : $independence;
$slick-alt-2-dot-hover-border      : 2px solid $independence;
$slick-alt-2-dot-active-bg-color   : $sea_blue;
$slick-alt-2-dot-active-border     : 2px solid $sea_blue;


// /* SLIDES - card-slide-description */ //
$slide-text-bg-color       : white; /*rgba(255, 255, 255, 0.7) ;*/
$slide-text-color          : $primary;
$slide-text-font           : 1.5rem/1.45 $base-font-family;



// /* SIDEBAR Container styles */

$sidebar-second-bg       : none;
$sidebar-second-border   : none;
$sidebar-second-margin   : 0;
$sidebar-second-padding  : 0;

$side-row-bg                   	: white;
$side-row-border               	: none;
$side-row-border-radius        	: 0 0 20px 0;
$side-row-box-shadow 						: none;
$side-row-padding              	: $vert-space $horz-space;
$side-row-inner-mobile-padding 	: 0;
$side-row-inner-padding        	: 0;
$side-row-margin               	: 0 0 $vert-space*1.25;



/* SIDEBAR CARDS */
@mixin palette-sidebar-cards {
	@include card-light();
}
@mixin palette-sidebar-card-more-link {
	@include card-more-link-default();
}
@mixin palette-sidebar-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-sidebar-button-link {
	@include button-link-default();
}
@mixin palette-sidebar-slick-controls {
	@include slick-controls-default();
}

$card-sidebar-border        : none;
$card-sidebar-border-radius : 0 0 20px 0;
$card-sidebar-shadow        : none; /*$box-shadow;*/
$card-sidebar-text-padding  : $vert-space 0;
$card-sidebar-margin-bottom : $vert-space !default;



// /* SECTION/NUGGET ROWS */ /
$section-row-padding          : $vert-space*2 0;
$section-inner-mobile-padding : 0 $horz-space/2;
$section-inner-padding        : 0 $horz-space;

// /*Default sets the card colours for main and additional also */
$palette-default-bg-color      : $isabelline;
$palette-default-title-color   : $primary;
$palette-default-text-color    : $primary;
$palette-default-link-color	   : $action-default;
$palette-default-link-hover    : $action-hover;

//
// --- Set the card style for the DEFAULT band by replacing the card-[mixin] in the mixin below
//
@mixin palette-default-cards {
	@include card-light();
}
@mixin palette-default-card-more-link {
	@include card-more-link-default();
}
@mixin palette-default-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-default-button-link {
	@include button-link-default();
}
@mixin palette-default-slick-controls {
	@include slick-controls-default();
}


$palette-alt-1-bg-color        : $platinum;
$palette-alt-1-title-color     : $primary;
$palette-alt-1-text-color      : $primary;
$palette-alt-1-link-color      : $action-default;
$palette-alt-1-link-hover      : $action-hover;

//
// --- Set the card style for the ALT-1 band by replacing the card-[mixin] in the mixin below
//
@mixin palette-alt-1-cards {
	@include card-light();
}
@mixin palette-alt-1-card-more-link {
	@include card-more-link-default();
}
@mixin palette-alt-1-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-alt-1-button-link {
	@include button-link-default();
}
@mixin palette-alt-1-slick-controls {
	@include slick-controls-default();
}


$palette-alt-2-bg-color        : white;
$palette-alt-2-title-color     : $primary;
$palette-alt-2-text-color      : $primary;
$palette-alt-2-link-color	   : $action-default;
$palette-alt-2-link-hover      : $action-hover;

//
// --- Set the card style for the ALT-2 band by replacing the card-[mixin] in the mixin below
//
@mixin palette-alt-2-cards {
	@include card-medium();
}
@mixin palette-alt-2-card-more-link {
	@include card-more-link-default();
}
@mixin palette-alt-2-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-alt-2-button-link {
	@include button-link-default();
}
@mixin palette-alt-2-slick-controls {
	@include slick-controls-default();
}


$palette-highlight-bg-color    	: $black_olive;
$palette-highlight-title-color 	: white;
$palette-highlight-text-color  	: white;
$palette-highlight-link-color		: $weldon_blue;
$palette-highlight-link-hover  	: $portal_primary;

//
// --- Set the card style for the HIGHLIGHT band by replacing the card-[mixin] in the mixin below
//

@mixin palette-highlight-cards {
	@include card-light();
}
@mixin palette-highlight-card-more-link {
	@include card-more-link-default();
}
@mixin palette-highlight-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-highlight-button-link {
	@include button-link-default();
}
@mixin palette-highlight-slick-controls {
	@include slick-controls-default();
}


$palette-alt-3-bg-color        : $glitter;
$palette-alt-3-title-color     : $primary;
$palette-alt-3-text-color      : $primary;
$palette-alt-3-link-color	   : $action-default;
$palette-alt-3-link-hover      : $action-hover;

//
// --- Set the card style for the ALT-3 band by replacing the card-[mixin] in the mixin below
//
@mixin palette-alt-3-cards {
	@include card-light();
}
@mixin palette-alt-3-card-more-link {
	@include card-more-link-default();
}
@mixin palette-alt-3-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-alt-3-button-link {
	@include button-link-default();
}
@mixin palette-alt-3-slick-controls {
	@include slick-controls-default();
}


// /* SEARCH */ //
$search-card-bg-color : white;


// /* HIGHCHARTS */ //
$highcharts-font-family      : 'Georgia', serif !default;
$highcharts-container-margin : $vert-space*1.5 0 !default;


// /* FOOTER */ //
$footer-first-bg           : white;
$footer-first-text         : $primary;
$footer-first-action       : $action-default;
$footer-first-action-hover : $action-hover;
$footer-first-border       : none;

//
// --- Set the card style for FOOTER-FIRST by replacing the card-[mixin] in the mixin below
//
@mixin palette-footer-first-cards {
	@include card-light();
}
@mixin palette-footer-first-card-more-link {
	@include card-more-link-default();
}
@mixin palette-footer-first-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-footer-first-slick-controls {
	@include slick-controls-default();
}


$footer-second-bg           : white;
$footer-second-text         : $primary;
$footer-second-action       : $action-default;
$footer-second-action-hover : $action-hover;

//
// --- Set the card style for FOOTER-SECOND by replacing the card-[mixin] in the mixin below
//
@mixin palette-footer-second-cards {
	@include card-light();
}
@mixin palette-footer-second-card-more-link {
	@include card-more-link-default();
}
@mixin palette-footer-second-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-footer-second-slick-controls {
	@include slick-controls-alt-2();
}

// /* FOOTER CARD-LINKS  -- Follow . social media icons */
$card-links-action-bg            : $trolley_grey;
$card-links-border-radius        : 50%;
$card-links-width                : 48px;
$card-links-height               : 48px;
$card-links-hover-opacity        : 1;
$card-links-hover-border-radius  : 50%;
$card-links-hover-bg             : $portal_primary;


$footer-third-bg            : $isabelline;
$footer-third-text          : $primary;
$footer-third-action        : $action-default;
$footer-third-action-hover  : $action-hover;
