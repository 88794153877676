$button-bg							: $portal_primary;
$button-hover-bg			 	: $black_olive;
$button-text					 	: white;
$button-border				 	: none;
$button-border-radius		: 40px;
$button-hover-text		 	: white;

$button-transition		 	: background-color 300ms linear 0s;

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  background-color: $button-bg;
  border: $button-border;
  border-radius: $button-border-radius;
  border: none;
  color: $button-text;
  font: $button-font;

  transition: $button-transition;
  &:hover,
  &:focus {
    background-color: $button-hover-bg;
    color:  $button-hover-text;
  }
}
input[type="reset"],
input[type="submit"][value="Reset"] {
	background-color: $trolley_grey;
	&:hover {
		background-color: $black_olive;
	}
}

a.button,
.button a {
	@include button-link-default();
  border-radius: $button-border-radius;
  font: $button-link-font;

}

.capsule-container {
    @include media($normal) {
    // top: 30px;
    top: 0;
  }
	a.button-capsule,
	.button-capsule a {
		background-color:$black_olive;
		color: white;
	//	font: normal 1rem/1.375 $base-font-family;

		&.button-download {
			background: $ico-download no-repeat 14px 50% / auto 20px $black_olive;
		}
		&.button-idea {
			background: $ico-bulb no-repeat 19px 50% / auto 20px $black_olive;
		}
		&.button-subscribe {
			background: $ico-subscribe no-repeat 14px 50% / 20px auto $black_olive;
		}

		&:hover {
			background-color: $portal_primary;
			color: white;
		}
	}
}

.user-content-buttons {
	@include button-link-default();
}

a.user-button {
	background-color:$black_olive;
	color:white;
	&:hover {
		background-color:$portal_primary;
		color:white;
	}
}
