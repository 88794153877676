fieldset {
	background: none;
	border: $primary-border;
	border-radius: none;
	margin: 0 0 $vert-space*1.5;
	padding: $vert-space $horz-space;

	legend {
		background: none;
		border: none;
		color: $independence;
		font-size: 	1.375rem;
		line-height:  1;
		margin:0 0 1rem;
		padding: 0;
		width: auto;
	}
}


input,
label,
select {
	display: block;
	font-family: $base-font-family;
	font-size: $base-font-size;
}

select::-ms-expand {  /* IE 10+ */
    display: none;
}

label {
	font-weight: 500;
	margin-bottom: $vert-space*0.35;
	color: $secondary;

	&.required::after {
		content: "*";
	}

	abbr {
		display: none;
	}
}

#{$all-text-inputs},
select[multiple=multiple],
textarea {
	background-color: white;
	border: $form-border;
	border-radius: $form-border-radius;
	box-shadow: $form-box-shadow;
	box-sizing: border-box;
	font-family: $base-font-family;
	font-size: $base-font-size;
	margin-bottom: $vert-space/2;
	padding: $vert-space/3 $horz-space/2;
	transition: border-color;
	width: 100%;

	&:hover {
		border-color: darken($form-border-color, 2%);
	}

	&:focus {
		//border-color: rgba($weldon_blue, 0.45);
		box-shadow: $form-box-shadow-focus;
		outline: none;
	}
}

textarea {
	resize: vertical;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color: lighten($secondary,20%);
}
::-moz-placeholder { /* Firefox 19+ */
	color: lighten($secondary,20%);
}
:-ms-input-placeholder { /* IE 10+ */
	color: lighten($secondary,20%);
}
:-moz-placeholder { /* Firefox 18- */
	color: lighten($secondary,20%);
}

input[type="search"] {
	@include appearance(none);
}

input[type="checkbox"],
input[type="radio"] {
	display: inline;
	margin-right: $vert-space/4;
}

input[type="file"] {
	padding-bottom: $vert-space/2;
	width: 100%;
}

select {
	background-color: white;
	width: auto;
	border: $form-border;
	border-radius: $form-border-radius;
	box-shadow: $form-box-shadow;
	box-sizing: border-box;
	font-family: $base-font-family;
	font-size: $base-font-size;
	margin-bottom: $vert-space/2;
	max-width: 100%;
	padding: $vert-space/3 $horz-space/2;
	transition: border-color;
	&:focus {
		box-shadow: $form-box-shadow-focus;
		outline: none;
	}
}

select {
	border-radius: 20px;
	/*background-color: $header-search-bg-color;*/
	background: $arrow-button-down-alt no-repeat 100% 50% / 35px 35px $header-search-bg-color;
	border: none;
	font-size: 14px;
	color: #40525F;
	-webkit-appearance: none;
	-moz-appearance: none;
	padding: 0 40px 0 20px;
	height: 35px;
	margin: 0;			
	outline: none;
	@media(max-width:720px) {
		width: 100%;
	}
}

select[multiple=multiple] {
	background-image: none;
	height: auto;
}

.form-item {
	.description {
		color: $trolley_grey;
		margin-bottom: $vert-space*1.5;
	}
}

#views-exposed-form-search-search {
	.views-exposed-form .views-exposed-widget .form-submit {
		margin-top: 0;
		margin-bottom: 1.6em;
	}
}

form.webform-client-form {
	.form-radios:not(#edit-submitted-people-number-of-individuals):not(#edit-submitted-organisations-number-of-orgs) {
		display: flex;
    flex-direction: column;
	}
	input[type="checkbox"] {
		display: none;
		&+ label {
			background: $checkbox no-repeat;
			display: inline-block;
			min-height: 20px;
			padding-left: 30px;
			&:hover {
				background: $checkbox-hover no-repeat;
			}
		}
		&:checked + label {
			background: $checkbox-selected no-repeat;
		}
	}

	input[type="radio"] {
		display: none;
		&+ label {
			background: $radio-button no-repeat;
			display: inline-block;
			min-height: 20px;
			padding-left: 30px;
			&:hover {
				background: $radio-button-hover no-repeat;
			}
		}
		&:checked + label {
			background: $radio-button-selected no-repeat;
		}
	}
	fieldset.webform-component-fieldset.collapsible {
		.fieldset-legend {
			background: $fieldest-arrow no-repeat 0 50%;
			padding-left: 20px;
			a.fieldset-title {
				color: white;
			}
		}
		&.collapsed {
			.fieldset-legend {
				background: $fieldest-arrow no-repeat 0 50%;
			}
		}
	}
	fieldset.webform-component-fieldset {
		fieldset.webform-component-fieldset {
			background: #ECF1F4;
			border: 0;
			box-shadow: none;
			border-radius: 0;
			legend {
				float: left;
				font-weight: 500;
				font-size: 24px;
				line-height: 36px;
				color: #252422;
				&+ .fieldset-wrapper {
					clear: both;
					input[type="text"] {
						background: #FFFFFF;
						border: 2px solid rgba(117, 150, 174, 0.5);
						border-radius: 4px;
					}
					ul.token-input-list {
						background: #FFFFFF;
						border: 2px solid rgba(117, 150, 174, 0.5);
						border-radius: 4px;
						input {
							border: none;
						}
					}
				}
				.fieldset-legend {
					background: none;
					padding-left: 0;
				}
			}
		}
	}
}
