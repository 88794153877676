button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  background-color: $button-bg;
  border: $button-border;
  border-radius: $button-border-radius;
  border: none;
  color: $button-text;
  cursor: pointer;
  display: inline-block;
  font: $button-font;
  padding: 0.75em 1em;
  text-decoration: none;
	text-transform: uppercase;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
	position: relative;
	z-index: 1;

  transition: $card-transition;
  &:hover,
  &:focus {
    background-color: $button-hover-bg;
    color:  $button-hover-text;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
input[type="reset"],
input[type="submit"][value="Reset"] {
	background-color: $weldon_blue;
	&:hover {
		background-color: $sea_blue;
	}
}

a.button,
.button a {
	@include button-link-default();
  border-radius: $button-border-radius;
  border: none;
  cursor: pointer;
  display: inline-block;
  font: $button-link-font;
  padding: 0.75em 1em;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

a.button-capsule,
.button-capsule a {
	background-color:$sea_blue;
	border: none;
	border-radius: 40px;
	color: white;
	cursor: pointer;
	display: inline-block;
	font: normal 1rem/1.375 $base-font-family;
	height: 42px;
	padding: 10px 20px 10px 40px;
	text-align: right;
	text-decoration: none;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;
	margin-left: 18px;

	@media(max-width:959px) {
		margin: 0 10px 10px;
	}

	@media (min-width:1030px){
		font-size: 1.25rem;
		line-height: 1.1;

	}
	&.button-download {
		background: $ico-download no-repeat 14px 50% / 20px 20px $sea_blue;
	}
	&.button-idea {
		background: $ico-bulb no-repeat 19px 50% / 15px 20px $sea_blue;
	}
	&.button-subscribe {
		background: $ico-subscribe no-repeat 14px 50% / 20px 14px $sea_blue;
	}
  &.button-add-event {
    background: $plus-button no-repeat 14px 50% / 20px 20px $sea_blue;
  }
  &.button-clock {
    background: $ico-clock no-repeat 14px 50% / 20px 20px $sea_blue;
  }

	&:hover {
		background-color: $independence;
		color: white;
	}
}

a.big-button {
  background-color: $action-default;
  text-align: center;
  padding: 45px;
  margin-right: 1em;
  display: inline-block;
  color: white !important;
  font-size: 28px;
  font-family: $title-font-family;
  font-weight: 500;
  line-height: 30px;
  &:before {
    content: "";
    display: inline-block;
    height: 32px;
    width: 32px;
    margin-right: 20px;
    // background: $ico-upload no-repeat 50% 50% / 32px auto $action-default;
  }
  &.button-search:before {
    background: $search-icon-white no-repeat 50% 50% / 32px auto transparent;
  }
  &.button-submit:before {
    background-size: 32px 32px;
    width: 32px;
    height: 32px;
    margin-right: 20px;
  }
  &.button-add:before {
    background-size: 32px 32px;
    width: 32px;
    height: 32px;
    margin-right: 20px;
  }
  &.button-join:before {
    background: $ico-person no-repeat 50% 50% / auto 32px transparent;
  }
  &.button-subscribe:before {
    background: $ico-subscribe no-repeat 50% 50% / auto 32px transparent;
  }

  &.orange {
    background-color: $mango_tango;
    &:hover {
      background-color: $medium_vermilion;
      &:before {
        background-color: transparent;
      }
    }
  }
  &:hover {
    background-color: $action-hover;
    color: white !important;
    &:before {
      background-color: $action-hover;
    }
  }
  @media(max-width:1300px) {
    margin: 0 10px 10px 10px;
  }
  @media(max-width:720px) {
    margin: 0 0 10px;
  }

  @media (max-width: 45em){
    width: 100%;
  }
}

a.button-add:before {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  margin-right: 10px;
  background: $plus-button no-repeat 50% 50% / 16px auto transparent;
}
a.button-submit:before {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  margin-right: 10px;
  background: $ico-upload no-repeat 50% 50% / 16px auto transparent;
}

.button-container {
  display: flex;
	flex-direction:column;
	flex-wrap:wrap;

	@media (min-width:540px) { flex-direction: row; }
  .big-button:last-child {
    margin-right: 0;
  }
  .add-button:last-child {
    margin-right: 0;
  }
}

.capsule-container {
	@media(max-width:1199px) {
		text-align: center;
		&.section-row {
			padding-bottom: 0;
		}

    .card-body {
      p {
        margin: 0 10px 10px;
        a,
        a:last-child {
          margin: 0 10px 10px;
        }
      }
    }
	}
	@include media($wide) {
		position: absolute;
		right: 0;
		// top: 40px;
    top: 0;
		.section-inner {
			float: right;
			position: relative;
			z-index: 1;
			.card-body {
				padding: 0;
			}
		}
	}
}

a.button.add-comment-button {
	border-radius: 0;
	display: block;
	text-transform: uppercase;
	font-weight: 700;
	text-align: center;
	margin-bottom: $card-margin-bottom;

	&:before {
		background: $open-quote-white no-repeat 0 50% / 22px auto transparent;
		content: " ";
		display: inline-block;
		margin-right: 20px;
    height: 18px;
		width: 22px;
	}
}

.user-content-buttons {
	@include button-link-default();
}

a.button.button-download {
  border-radius: 0;
  display: block;
  margin-bottom: 20px;
  text-align: center;
  &:before {
    content:"";
    display: inline-block;
    margin-right: 10px;
    width: 22px;
    height: 21px;
    background: $ico-download;
    font-size: 1.125rem;
    vertical-align: bottom;
  }
}

a.button.button-register {
	border-radius: 0;
	display: block;
	margin-bottom: 20px;
	text-align: center;
	&:before {
		content:"";
		display: inline-block;
		margin-right: 10px;
		width: 18px;
		height: 21px;
		background: $ico-person;
		font-size: 1.125rem;
		vertical-align: bottom;
	}
}

// BOOKMARK BUTTON
.flag-bookmark a,
.flag-bookmark a.flag {
	background-color:$button-bg;
	padding: 0.75em 1em;
	color: white;
	border-radius: 0;
	display: inline-block;
	font-size: 1.125em;
	font-weight: 600;
	margin-bottom: 20px;
	text-align: center;
	text-transform: uppercase;
	width:100%;
	&:hover {
		color:white;
		background-color:$button-hover-bg;
	}
	&:before {
		content: "";
		display: inline-block;
		height: 22px;
		width: 22px;
		margin-right: 10px;
		vertical-align:middle;
	}
}

a.user-button {
	background-color:$sea_blue;
	color:white;
	padding:20px;
	display:block;
	text-align:center;
	padding: 12px 10px;
	text-transform:uppercase;
	font: $button-link-font;
	margin-bottom:20px;
	border-radius:0px;
	&:hover {
		background-color:$independence;
		color:white;
	}
}

.flag-bookmark {
	a.unflag-action {
		&:before {
			background: $minus-button no-repeat 50% 50% / 22px auto transparent;
		}
	}
	a.flag-action {
		&:before {
			background: $plus-button no-repeat 50% 50% / 22px auto transparent;
		}
	}
}
