.view-filters-toggle {
	span {
//		background-color: white;
//		box-shadow: $box-shadow;

		&:after {
			background: $plus no-repeat 50% 50% / 12px auto $portal_primary;
		}
		
		&:hover {
			&:after {
				background-color: $black_olive;
			}
		}	
	}
}

.view-filters {
	&.expanded {
		.view-filters-toggle {
			span {
//				box-shadow: 0 -3px 6px 0 rgba(0,0,0,0.1);
				&:after {
//					background-image: $minus;
//					background-size: 12px auto;
				}
			}
		}
	}
	label {
//		color: $primary;	
	}
}


.chosen-container-multi .chosen-choices {
	background: $arrow-button-down-alt no-repeat 100% 50% /35px auto $platinum;
	
	li.search-field {
		float: none;
		input[type="text"] {
//			color: $primary !important;
		}
	}
}

.chosen-container-single .chosen-single {
	background: $arrow-button-down-alt no-repeat 100% 50% /35px auto $platinum;
}
	
.chosen-container {
	.chosen-drop {
//		border: $primary-border;
//		background: #fff;
//		box-shadow: $box-shadow;
	}
	.chosen-results {
//		color: $primary;
		li.active-result {
			&.highlighted {
				background: $portal_primary;
			}
		}	
	}
}
	
.layout-trigger {
//	background: white;
//	box-shadow: $box-shadow;
	color: $primary;
	.trigger-list {
		background: $trigger-list no-repeat 50% 50% / 29px auto transparent;
		
		&:hover { background: $trigger-list-hover no-repeat 50% 50% / 29px auto transparent; }
		
		&.active { background: $trigger-list-active no-repeat 50% 50% / 29px auto $black_olive; }
	}
	.trigger-grid {
		background: $trigger-grid no-repeat 50% 50% / 30px auto transparent;
		
		&:hover { background: $trigger-grid-hover no-repeat 50% 50% / 30px auto transparent; }
		
		&.active { background: $trigger-grid-active no-repeat 50% 50% / 30px auto $black_olive; }
	}
}

#edit-bef-sort-options,
.views-exposed-form .views-widget-sort-by,
.views-exposed-form .views-widget-sort-order,
.views-exposed-form .form-item-sort-bef-combine,
.views-exposed-form #edit-dates-wrapper {
	border: none;
	margin: 0 118px 0 0;
	float: right;
	padding: 0;
	legend { display: none;}
	label,
	select { display: inline-block; }
	label {
		font-size: 1.125rem;
		font-weight: 500;
		line-height: 1.22222222222222;
		margin-right: 8px;
	}
	.form-select,
	.form-item-sort-bef-combine,
	.views-widget-filter-dates {
		background-clip: padding-box;
		background-color: white;
		background-image: $arrow-button-down-alt-2;
		background-position: calc(100% - 10px) 50%;
		background-size: 24px auto;
		background-repeat: no-repeat;
		border-radius: 0;
		box-shadow: $box-shadow;
		color: $primary;
		font: 1rem/1.1875 $base-font-family;	
		margin-right: 3px;
		margin-bottom:0;
		padding: 10px 54px 10px 20px;
		
		-webkit-appearance: caret;
		-moz-appearance: radio-container;
		appearance: caret;
	}
}

.views-exposed-form .form-item-sort-bef-combine {
	margin: 0 105px 0 0;
}
.view-id-search_resources .views-exposed-form .form-item-sort-bef-combine {
	margin: 0 25px 0 0;
}
.view-blog,
.view-search-resources,
.view-news,
.view-id-members {
	.view-filters {
		.views-submit-button,
		.views-reset-button {
			input[type="submit"] {
				height: 39px;
				position: absolute;
				padding: 7px 20px;
				top: 130px;
				@media (min-width:540px) {
					top: 70px;
				}
			}
		}
		.views-reset-button input[type="submit"] { right: 30px; }
		.views-submit-button input[type="submit"] { right: 140px; }

		#edit-secondary-wrapper {
			background-color: white;
			fieldset {
				legend {
					visibility: hidden;
					height: 0;
				}
				.fieldset-wrapper {
					&:before {
						content: "Filter by";
						display: block;
						font-size: 1.5rem;
						font-weight: 500;
						line-height: 1.2;
						text-transform: uppercase;
						margin-bottom: 20px;
					}
				}
				.form-type-select {
					@include media($narrow) {
						@include span-columns(4);
					}
					.chosen-container {
						display: block;
						width: 100% !important;
					}
				}
			}
		}

	}
	// .view-header {
	// 	@include clearfix;
	// 	.view-results {
	// 		float: left;
	// 		margin-top: -66px;
	// 	}
	// 	.layout-trigger {
	// 		float: right;
	// 		margin-top: -72px;
	// 		margin-bottom:30px;
	// 	}
	// }
}

// HELP LIBRARY SPECIFIC STYLES
.view-search-resources {
	.view-filters {
		.views-exposed-form .views-exposed-widget {
			padding:0;
		}
		
		.views-widget-filter-search_api_views_fulltext {
			float: none;
			padding-bottom: 40px;
			@media (min-width:540px) {
				padding-bottom: 0;
				input.form-text {
					width: calc(100% - 168px);
				}
			}
		}
		
		.resource-results {
			color: $trolley_grey;
			font-size: 1.375rem;
			font-weight: bold;
			line-height: 1.13636363636364;
			margin:30px 0;
			padding-top:9px;
		}

		.views-exposed-widgets {
			display: flex;
			flex-flow: row wrap;
			position: relative;
			#edit-keywords-wrapper { order: 1; flex: 1 100%; }
			.views-submit-button { order: 2; }
			.views-reset-button { order: 3; }
			.expand-trigger { order: 4; flex: 2 100%; }
			#edit-secondary-wrapper { order: 5; flex: 3 100%; }
		  	#edit-keywords-wrapper {
				padding: 0;
				label {
					display: inline-block;
					background: #FFFFFF;
					margin-bottom: 0;
					padding: 9px 30px;
					text-transform: uppercase;
					font-size: 20px;
					font-weight: 500;
					line-height: 24px;
					box-shadow: 0px -1px 6px 0px rgba(0,0,0,0.1);
				}
				.views-widget {
					background: #FFFFFF;
					box-shadow: 0px 0px 6px rgba(0,0,0,0.1);
					.form-item {
						padding: 30px;
					}
				}
		 	}
		  	.expand-trigger {
				width: 100%;
				display: flex;
				background-color: #F0F0F0;
				padding: 30px;
				text-transform: uppercase;
				font-size: 20px;
				font-weight: 500;
				line-height: 24px;
				margin-top: -10px;
				border-top: 1px solid #E6E6E6;
				box-shadow: 0px 2px 6px rgba(0,0,0,0.1);
				&:hover {
					cursor: pointer;
				}
				&:after {
			  		content: "";
			  		display: block;
			  		height: 24px;
			  		width: 24px;
			  		background: $portal_primary $plus no-repeat scroll;
			  		margin-left: 20px;
			  		border-radius: 50%;
			  		background-size: 12px;
			  		background-position: 50% 50%;
				}
		  	}
		}
		&.expanded {
			#edit-secondary-wrapper {
			  display: block !important;
			}
			.expand-trigger:after {
				background: $portal_primary $minus no-repeat scroll;
				background-size: 12px;
				background-position: 50%;
			}
		}
		.views-exposed-form {
			.views-widget-filter-secondary {
				border-top: 1px solid rgba(44,63,78,0.1);
				float: none;
				margin:0;
				padding: 20px 30px;
			}
			.views-exposed-widget .form-submit {
				margin-top: 1px;
			}
		}

		#edit-secondary-wrapper {
			.bef-secondary-options {
				.chosen-container-single .chosen-single div b {
					display:none;
				}
				.form-type-select {
					margin-top: 10px;
					margin-bottom: 20px;
					@include media($narrow) {
						@include span-columns(3);
					}
					.chosen-container {
						display: block;
						width: 100% !important;
					}
				}
				.container-inline-date {
					clear:none;
					@include span-columns(3);
						@media (max-width:719px){
							@include span-columns(6);
							width:47.5%;
						}
						@media (max-width:540px){
							@include span-columns(12);
						}
					
					&.container-date-from {
						clear:left;
						@media (max-width:719px){
							@include span-columns(6);
							width:47.5%;
						}
						@media (max-width:540px){
							@include span-columns(12);
						}
					}
					.form-item { 
						width:100%;
					}
					.form-type-date-popup {
						.form-item-from-date label,
						.form-item-from-date .description,
						.form-item-to-date label,
						.form-item-to-date .description,
						fieldset.collapsible .fieldset-legend {
							display: none;
						}
						#edit-from,
						#edit-to {
							width:100%;
						}
						input#edit-from-datepicker-popup-0,
						input#edit-to-datepicker-popup-0 {
							background: $platinum;
							border:none;
							width:100%;
							
						}
					}
				}
				.form-item.form-type-select.form-item-int {
					display: none;
				}
			}
		}
	}
}

.collapsible-filters 	.view-filters .view-header {
	@include clearfix;
	.view-results {
		float: left;
	}
}

#help-library-summary {
	margin-top: 20px;
}

.page-number select {
	background: $arrow-button-down-alt-2 no-repeat calc(100% - 10px) 50% / 24px 24px #fff;
}

#help-library-summary .remove {
	color: $portal_primary !important;
}

.view-resources-search fieldset.collapsible {
	a {
		background-image: url("../images/filter-collapse.svg") !important;
	}
}

.view-resources-search .filter-wrapper #edit-secondary-wrapper .form-item.form-type-select fieldset.collapsible.collapsed a {
	background-image: url("../images/filter-expand.svg") !important;
}