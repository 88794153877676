html, body {
	color:$primary;
	font-family:$base-font-family;
	font-size:$base-font-size;
	line-height:$base-line-height;
	-webkit-font-smoothing: antialiased;
}


h1, h2, h3, h4, h5, h6 {
	color:$primary;
	font-family:$title-font-family;
	margin-top:0;
	text-transform:none;
	
	&.card-title {
		font-family:$base-font-family;
	}
	&.card-title-sm {
		font-family:$base-font-family;
		font-size:1em;
		margin-bottom:$vert-space/4;
		
		a {
			color:$action-default;
			
			&:hover {
				color:$action-hover;
			}
		}
	}
}
h1, h2 {
	font-weight:normal;
}
h3, h4, h5, h6 {
	font-weight:500;
}

.l-region--sidebar-second {
	h3, h4, h5, h6 {
		font-family:$base-font-family;
	}
}
h1 {
	font:500 3.125rem/1.08 $title-font-family;
	margin:0 $horz-space/2 $vert-space/2 0;
	text-align: left;
	clear: both;
	@include media($normal) {
		//font-size:3.125rem;
		//line-height: 1.33333333333333;
		text-align: center;
	}
	.card-title,
	.card-title-field {
		background: $title-prefix no-repeat 0 100% / 38px 65px,
								$title-suffix no-repeat 100% 0 / 38px 65px;
		color:$primary;
		display: inline-block;
		padding: 18px 25px;
		
		sup {
			margin-right: 12px;
		}
	}
}

h2 {
	font-size: 2.375rem; //1.5625rem;
	line-height:  1.25; //1.32;
	margin-bottom: $vert-space/2; //0.5em;
	font-weight: 500; //600; 

	@include media($normal) {
		font-size: 2.75rem; //1.75rem;
		line-height: 1.36363636363636; //1.28571428571429;
	}
	
	&.line-through {
		position: relative;
		z-index: 1;
		font-size:2.75rem;
		line-height: 1.36363636363636;
		font-weight: 600;
		
		@media (max-width:719px) {
			border-bottom: 2px solid $mango_tango;
			border-top: 2px solid $mango_tango;
			padding: $vert-space 0;
			
			br {
				display: none;
			}
		}
		
		@include media($narrow) {
			&:before {
				border-top: 2px solid $mango_tango;
				content: "";
				margin: 0 auto;
				position: absolute;
				top: 50%;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				z-index: -1;
			}
		}
		span {
			display: inline-block;
			padding:0 20px;
			
			@media (max-width:320px) {
				padding:0;
			}
		}
	}
}
.postscript-first .section-row h2, .content-hero .section-row h2 {
	&.line-through {
		font-size:2.75rem;
		line-height: 1.36363636363636;
	}
}
h3 { 
	font-size: 2.125rem; //1.375rem;
	line-height: 1.35294117647059; // /*1.273;*/
	margin-bottom:0.5rem;
	
	@include media($normal) {
		font-size: 2.375rem; //1.5rem;
		line-height:  1.34210526315789;  //1.33333333333333;
	}
}
h3.block__title {
	
	font-size:1.375em;
	
	@media screen and (min-width: 768px) {
		text-align: center;
		font-size: 1.750rem;
	}
}
h4 {
	font-size: 1.75rem; //1.25rem;
	line-height: 1.35714285714286; //1.375;
	margin-bottom:0.5em;

	@include media($normal) {
		font-size: 2.125rem; //1.375rem;
		line-height:  1.35294117647059; //1.36363636363636;
	}
}
h5 {
	font-size: 1.5rem; //1.063rem;
	line-height: 1.33333333333333; //1.375;
	margin-bottom:0.5em;

	@include media($normal) {
		font-size: 1.75rem; //1.1875rem;
		line-height:  1.35714285714286; //1.42105263157895;
	}
}
h6 {
	font-size:1.375; // 1.063rem;
	line-height: 1.45454545454545; //1.375;
	margin-bottom:0.5em;

	@include media($normal) {
		font-size: 1.5rem; //1.1875rem;
		line-height: 1.33333333333333; // 1.42105263157895;
	}
}
p {
	margin:0 0 $vert-space;
}
main,
.postscript-first {
	.card-body,
	.card-description {
		p, address, pre, ul, ol {
			font-outer-wrappersize: $base-font-size;
			line-height: $base-line-height;
			
			&.smaller-text {
				font-size: 0.750rem;
				line-height: 1.5;
				
				p, li {
					font-size: 1em;
				}
			}
			&.larger-text {
				font-size: 1.375rem;
				line-height: 1.41666666666667;
				p, li {
					font-size: 1em;
				}
			}				
			@include media($normal) {
				font-size:1.125rem;
				line-height: 1.44444444444444;
				
				&.smaller-text {
					font-size: 0.750rem;
					line-height: 1.5;
				}
				&.larger-text {
					font-size: 1.5rem;
					line-height: 1.41666666666667;
				}				
			}
		}
		
		ol, li {
			color: $weldon_blue;
			
		}

		
		blockquote {
			font: 500 1.75rem/1.35714285714286 $title-font-family;
			p, address, pre, ul, ol {
				font: 500 1.75rem/1.35714285714286 $title-font-family;
			}
			
			:first-child {
				&:before {
					font-size: 3.75rem;
					line-height: 0;
				}
			}
			:last-child {
				&:after {
					font-size: 3.75rem;
					line-height: 0;
				}
			}
		}					
		
		.caption {
			display: block;
			line-height: 1.5em;
			text-align: left;

			p {
				font-size: 0.75em;
				line-height: 1.5;

				@include media($normal) {
					font-size:0.875rem;
					line-height: 1.5;
				}
			}
		}
	}
}
b, strong {
	font-weight:700;
}

a, a:link, a:active, a:visited {
	color:$action-default;
	text-decoration:none;

	&.active {
		color:$action-active;
		text-decoration:none;
	}

	&:hover,
	h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
		color:$action-hover;
		text-decoration:none;
	}
	
	&:not([href]) {
		color: inherit;
	}
}

.date,
.card-date {
	font-size:0.750em;
	font-style:normal;
}

em, i {
	font-style: italic;
}

sup, sub {
	font-size: smaller;
	&:before {
		content: "";
		margin-left: 0.125rem;
	}
}
sup { vertical-align: super; }
sub { vertical-align:sub; }

h1, h2, h3, h4 h5 {
	sup { top: -0.125rem; line-height: 1.5; }
	sub { bottom: -0.125rem; line-height: 1.5; }
}


.colon { font-weight:400; }
