.node-person,
.page-user {
  .card-links ul li,
  .card-links {
    a /* Unidentified */ {
			float: left;
			height: 60px;
      line-height: 1.25;
      margin:0 1px 1px;
      padding:$vert-space $horz-space;
			text-indent: -9999px;
			width: 80px;
    }
  }
	.card-profile-links {
		h2 {
			background: white;
			font-size: 1.5rem;
			font-weight: 500;
    	line-height: 1.333333333333333;
			padding: $vert-space $horz-space;
			margin: 0;
		}
	}
}

.node-person .card-profile-links ,
.page-user .card-profile-links  {

	.card-links ul li,
  .card-links {
    a /* Unidentified */ {
      background:$ico-web no-repeat 50% 50% / auto 20px darken($platinum, 5%);
			box-sizing: border-box;
      color:$action-default;
      display: block;
      overflow: hidden;
      word-wrap:break-word;
     
       &:hover {
         background: $ico-web-over no-repeat 50% 50% / auto 20px $portal_primary;
         color:$action-hover;
       }
    }
		
  	/* Institutional profile */
    .institution a,
    &.institution a,
    a[href*=".ac.uk"],
    a[href*=".edu"] {
      background: $ico-institution no-repeat 50% 50% / auto 22px darken($platinum, 5%);
      
			&:hover {
				background: $ico-institution-over no-repeat 50% 50% / auto 22px $portal_primary;
			}
    }		
		
  	/* Research Gate */
    .research-gate a,
    &.research-gate a,
    a[href*="researchgate.net"],
    a[href*="researchgate.com"],
    a[href^="https://www.researchgate.net"],
    a[href^="http://www.researchgate.net"],
    a[href^="https://researchgate.net"],
    a[href^="http://researchgate.net"] {
			background: $ico-researchgate no-repeat 50% 50% / auto 20px darken($platinum, 5%);
      
			&:hover {
				background: $ico-researchgate-over no-repeat 50% 50% / auto 20px $portal_primary;
			}
    }		
		
 		 /* Email */
    .email a,
    &.email a,
    a[href^="mailto:"],
    a[href*="@"] {
      background: $ico-email no-repeat 50% 50% / auto 18px darken($platinum, 5%);
      
		&:hover {
			background: $ico-email-over no-repeat 50% 50% / auto 18px $portal_primary;
		}
    }
		
  	/* Google Scholar */
    .g-scholar a,
    &.g-scholar a,
    a[href*="scholar.google"] {
    background: $ico-gscholar no-repeat 50% 50% / auto 20px darken($platinum, 5%);

		&:hover {
			background: $ico-gscholar-over no-repeat 50% 50% / auto 20px $portal_primary;
		}
    }
  
  /* linkedin */ 
    .linkedin a,
    &.linkedin a,
    a[href*="linkedin.com"],
    a[href^="https://www.linkedin.com"],
    a[href^="http://www.linkedin.com"],
    a[href^="https://linkedin.com"],
    a[href^="http://linkedin.com"] {
      background: $ico-linkedin no-repeat scroll 50% 50% / auto 20px darken($platinum, 5%);
      
      &:hover {
        background:$ico-linkedin-over no-repeat scroll 50% 50% / auto 20px $portal_primary;
      }
    }  
  
  /* twitter */ 
    .twitter a,
    &.twitter a,
    a[href*="twitter.com"],
    a[href^="https://www.twitter.com"],
    a[href^="http://www.twitter.com"],
    a[href^="https://twitter.com"],
    a[href^="http://twitter.com"] {
      background: $ico-twitter no-repeat scroll 50% 50% / auto 20px darken($platinum, 5%);
      
      &:hover {
        background: $ico-twitter-over no-repeat scroll 50% 50% / auto 20px $portal_primary;
      }
    }
  
  /* facebook */ 
    .facebook a,
    &.facebook a,
    a[href*="facebook.com"],
    a[href^="https://www.facebook.com"],
    a[href^="http://www.facebook.com"],
    a[href^="https://facebook.com"],
    a[href^="http://facebook.com"] {
      background: $ico-facebook no-repeat scroll 50% 50% / auto 20px darken($platinum, 5%);
    
      &:hover {
        background: $ico-facebook-over no-repeat scroll 50% 50% / auto 20px $portal_primary;
      }
    }
  
  /* google+ */ 
    .g-plus a,
		&.g-plus a,
    a[href*="plus.google.com"],
    a[href^="https://www.plus.google.com"],
    a[href^="http://www.plus.google.com"],
    a[href^="https://plus.google.com"],
    a[href^="http://plus.google.com"] {
      background:$ico-gplus no-repeat scroll 50% 50% / auto 20px darken($platinum, 5%);
      
      &:hover {
        background: $ico-gplus-over no-repeat scroll 50% 50% / auto 20px $portal_primary;
      }
    }
  
	 /* google(+) */ 
		.google a,
		&.google a {
      background:$ico-gplus no-repeat scroll 50% 50% / auto 20px darken($platinum, 5%);
      
      &:hover {
        background: $ico-gplus-over no-repeat scroll 50% 50% / auto 20px $portal_primary;
      }
    }
  
  /* orcid */ 
    .orcid a,
    &.orcid a,
		a[href*="orcid.com"],
    a[href^="https://www.orcid.com"],
    a[href^="http://www.orcid.com"],
    a[href^="https://orcid.com"],
    a[href^="http://orcid.com"] {
      background:$ico-orcid no-repeat scroll 50% 50% / auto 18px darken($platinum, 5%);
      
      &:hover {
        background: $ico-orcid-over no-repeat scroll 50% 50% / auto 18px $portal_primary;
      }
    }
  
  /* YouTube */
    .youtube a,
    &.youtube a,
    a[href*="youtube.com"],
    a[href^="https://www.youtube.com"],
    a[href^="http://www.youtube.com"],
    a[href^="https://youtube.com"],
    a[href^="http://youtube.com"] {
      background:$ico-youtube no-repeat scroll 50% 50% / auto 17px darken($platinum, 5%);
      
      &:hover {
        background: $ico-youtube-over no-repeat scroll 50% 50% / auto 17px $portal_primary;
      }
    }
  
  /* flickr */
    .flickr a,
    &.flickr a,
    a[href*="flickr.com"],
    a[href^="https://www.flickr.com"],
    a[href^="http://www.flickr.com"],
    a[href^="https://flickr.com"],
    a[href^="http://flickr.com"] {
      background:$ico-flickr no-repeat scroll 50% 50% / auto 10px darken($platinum, 5%);
      
      &:hover {
        background: $ico-flickr-over no-repeat scroll 50% 50% / auto 10px $portal_primary;
      }
    }		
  }
}

.soc-accelerators  {

	.card-links ul li,
  .card-links {
    a /* Unidentified */ {
      background:$ico-web no-repeat 50% 50% / auto 20px white;
			box-sizing: border-box;
      color:$action-default;
      display: block;
      overflow: hidden;
      word-wrap:break-word;
     
       &:hover {
         background: $ico-web-over no-repeat 50% 50% / auto 20px $portal_primary;
         color:$action-hover;
       }
    }
		
  	/* Institutional profile */
    .institution a,
    &.institution a,
    a[href*=".ac.uk"],
    a[href*=".edu"] {
      background: $ico-institution no-repeat 50% 50% / auto 22px white;
      
			&:hover {
				background: $ico-institution-over no-repeat 50% 50% / auto 22px $portal_primary;
			}
    }		
		
  	/* Research Gate */
    .research-gate a,
    &.research-gate a,
    a[href*="researchgate.net"],
    a[href*="researchgate.com"],
    a[href^="https://www.researchgate.net"],
    a[href^="http://www.researchgate.net"],
    a[href^="https://researchgate.net"],
    a[href^="http://researchgate.net"] {
			background: $ico-researchgate no-repeat 50% 50% / auto 20px white;
      
			&:hover {
				background: $ico-researchgate-over no-repeat 50% 50% / auto 20px $portal_primary;
			}
    }		
		
 		 /* Email */
    .email a,
    &.email a,
    a[href^="mailto:"],
    a[href*="@"] {
      background: $ico-email no-repeat 50% 50% / auto 18px white;
      
		&:hover {
			background: $ico-email-over no-repeat 50% 50% / auto 18px $portal_primary;
		}
    }
		
  	/* Google Scholar */
    .g-scholar a,
    &.g-scholar a,
    a[href*="scholar.google"] {
    background: $ico-gscholar no-repeat 50% 50% / auto 20px white;

		&:hover {
			background: $ico-gscholar-over no-repeat 50% 50% / auto 20px $portal_primary;
		}
    }
  
  /* linkedin */ 
    .linkedin a,
    &.linkedin a,
    a[href*="linkedin.com"],
    a[href^="https://www.linkedin.com"],
    a[href^="http://www.linkedin.com"],
    a[href^="https://linkedin.com"],
    a[href^="http://linkedin.com"] {
      background: $ico-linkedin no-repeat scroll 50% 50% / auto 20px white;
      
      &:hover {
        background:$ico-linkedin-over no-repeat scroll 50% 50% / auto 20px $portal_primary;
      }
    }  
  
  /* twitter */ 
    .twitter a,
    &.twitter a,
    a[href*="twitter.com"],
    a[href^="https://www.twitter.com"],
    a[href^="http://www.twitter.com"],
    a[href^="https://twitter.com"],
    a[href^="http://twitter.com"] {
      background: $ico-twitter no-repeat scroll 50% 50% / auto 20px white;
      
      &:hover {
        background: $ico-twitter-over no-repeat scroll 50% 50% / auto 20px $portal_primary;
      }
    }
  
  /* facebook */ 
    .facebook a,
    &.facebook a,
    a[href*="facebook.com"],
    a[href^="https://www.facebook.com"],
    a[href^="http://www.facebook.com"],
    a[href^="https://facebook.com"],
    a[href^="http://facebook.com"] {
      background: $ico-facebook no-repeat scroll 50% 50% / auto 20px white;
    
      &:hover {
        background: $ico-facebook-over no-repeat scroll 50% 50% / auto 20px $portal_primary;
      }
    }
  
  /* google+ */ 
    .g-plus a,
		&.g-plus a,
    a[href*="plus.google.com"],
    a[href^="https://www.plus.google.com"],
    a[href^="http://www.plus.google.com"],
    a[href^="https://plus.google.com"],
    a[href^="http://plus.google.com"] {
      background:$ico-gplus no-repeat scroll 50% 50% / auto 20px white;
      
      &:hover {
        background: $ico-gplus-over no-repeat scroll 50% 50% / auto 20px $portal_primary;
      }
    }
  
	 /* google(+) */ 
		.google a,
		&.google a {
      background:$ico-gplus no-repeat scroll 50% 50% / auto 20px white;
      
      &:hover {
        background: $ico-gplus-over no-repeat scroll 50% 50% / auto 20px $portal_primary;
      }
    }
  
  /* orcid */ 
    .orcid a,
    &.orcid a,
		a[href*="orcid.com"],
    a[href^="https://www.orcid.com"],
    a[href^="http://www.orcid.com"],
    a[href^="https://orcid.com"],
    a[href^="http://orcid.com"] {
      background:$ico-orcid no-repeat scroll 50% 50% / auto 18px white;
      
      &:hover {
        background: $ico-orcid-over no-repeat scroll 50% 50% / auto 18px $portal_primary;
      }
    }
  
  /* YouTube */
    .youtube a,
    &.youtube a,
    a[href*="youtube.com"],
    a[href^="https://www.youtube.com"],
    a[href^="http://www.youtube.com"],
    a[href^="https://youtube.com"],
    a[href^="http://youtube.com"] {
      background:$ico-youtube no-repeat scroll 50% 50% / auto 17px white;
      
      &:hover {
        background: $ico-youtube-over no-repeat scroll 50% 50% / auto 17px $portal_primary;
      }
    }
  
  /* flickr */
    .flickr a,
    &.flickr a,
    a[href*="flickr.com"],
    a[href^="https://www.flickr.com"],
    a[href^="http://www.flickr.com"],
    a[href^="https://flickr.com"],
    a[href^="http://flickr.com"] {
      background:$ico-flickr no-repeat scroll 50% 50% / auto 10px white;
      
      &:hover {
        background: $ico-flickr-over no-repeat scroll 50% 50% / auto 10px $portal_primary;
      }
    }		
  }
}



#site-header .view-header-sections,
#site-footer {
	.card-links {
		display: inline-block;
		margin-right: 10px;
		text-indent: -9999px;
		
		a {
			background-color: $card-links-action-bg;
			border-radius: $card-links-border-radius;
			width: $card-links-width;
			height: $card-links-height;
			display:block;
			margin:0;
			
			&:hover {
				opacity: $card-links-hover-opacity;
				border-radius: $card-links-hover-border-radius;
				background-color: $card-links-hover-bg;
			}
		}
		
		/* newsletter */
		.newsletter a,
		a[href^="http://eepurl.com"] {
			background: $ico-newsletter no-repeat scroll 50% 50% / 19px 18px $card-links-action-bg;
			&:hover {
				background: $ico-newsletter-over no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
			}
		}

		/* linkedin */
		.linkedin a,
		a[href^="https://www.linkedin.com"],
		a[href^="http://www.linkedin.com"],
		a[href^="https://linkedin.com"],
		a[href^="http://linkedin.com"] {
			background: $ico-linkedin-white no-repeat scroll 50% 50% / 19px 18px $card-links-action-bg;
			&:hover {
				background: $ico-linkedin-white no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
			}
		}
		
		/* twitter */
		.twitter a,
		a[href^="https://www.twitter.com"],
		a[href^="http://www.twitter.com"],
		a[href^="https://twitter.com"], 
		a[href^="http://twitter.com"] {
			background: $ico-twitter-white no-repeat scroll 50% 50% / 20px 20px $card-links-action-bg;
			&:hover {
				background: $ico-twitter-white no-repeat scroll 50% 50% / 20px 20px $card-links-hover-bg;
			}
		}

		/* facebook */
		.facebook a,
		a[href^="https://www.facebook.com"],
		a[href^="http://www.facebook.com"],
		a[href^="https://facebook.com"],
		a[href^="http://facebook.com"] {
			background: $ico-facebook-white no-repeat scroll 50% 50% / 19px 18px $card-links-action-bg;
			&:hover {
				background: $ico-facebook-white no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
			}
		}

		/* google+ */
		.g-plus a,
		a[href^="https://www.plus.google.com"],
		a[href^="http://www.plus.google.com"],
		a[href^="https://plus.google.com"],
		a[href^="http://plus.google.com"] {
			background:$ico-gplus-white no-repeat scroll 50% 50% / 19px 18px $card-links-action-bg;
			&:hover {
				background: $ico-gplus-white no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
			}
		}

		/* youtube */
		.youtube a,
		a[href^="https://www.youtube.com"],
		a[href^="http://www.youtube.com"],
		a[href^="https://youtube.com"],
		a[href^="http://youtube.com"] {
			background:$ico-youtube-white no-repeat scroll 50% 50% / 19px 18px $card-links-action-bg;
			&:hover {
				background: $ico-youtube-white no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
			}
		}
		
		
		
  	/* Institutional profile */
    .institution a,
    &.institution a,
    a[href*=".ac.uk"],
    a[href*=".edu"] {
      background: $ico-institution-over no-repeat 50% 50% / auto 22px $card-links-action-bg;
      
			&:hover {
				background: $ico-institution-over no-repeat 50% 50% / auto 22px $card-links-hover-bg;
			}
    }		
		
  	/* Research Gate */
    .research-gate a,
    &.research-gate a,
    a[href*="researchgate.net"],
    a[href*="researchgate.com"],
    a[href^="https://www.researchgate.net"],
    a[href^="http://www.researchgate.net"],
    a[href^="https://researchgate.net"],
    a[href^="http://researchgate.net"] {
			background: $ico-researchgate-over no-repeat 50% 50% / auto 20px $card-links-action-bg;
      
			&:hover {
				background: $ico-researchgate-over no-repeat 50% 50% / auto 20px $card-links-hover-bg;
			}
    }		
		
 		 /* Email */
    .email a,
    &.email a,
    a[href^="mailto:"],
    a[href*="@"] {
      background: $ico-email-over no-repeat 50% 50% / auto 18px $card-links-action-bg;
      
			&:hover {
				background: $ico-email-over no-repeat 50% 50% / auto 18px $card-links-hover-bg;
			}
    }
		
  	/* Google Scholar */
    .g-scholar a,
    &.g-scholar a,
    a[href*="scholar.google"] {
    	background: $ico-gscholar-over no-repeat 50% 50% / auto 20px $card-links-action-bg;

			&:hover {
				background: $ico-gscholar-over no-repeat 50% 50% / auto 20px $card-links-hover-bg;
			}
    }

  
	 /* google(+) */ 
		.google a,
		&.google a {
      background:$ico-gplus-over no-repeat scroll 50% 50% / auto 20px $card-links-action-bg;
      
      &:hover {
        background: $ico-gplus-over no-repeat scroll 50% 50% / auto 20px $card-links-hover-bg;
      }
    }
  
  /* orcid */ 
    .orcid a,
    &.orcid a,
		a[href*="orcid.com"],
    a[href^="https://www.orcid.com"],
    a[href^="http://www.orcid.com"],
    a[href^="https://orcid.com"],
    a[href^="http://orcid.com"] {
      background:$ico-orcid-over no-repeat scroll 50% 50% / auto 18px $card-links-action-bg;
      
      &:hover {
        background: $ico-orcid-over no-repeat scroll 50% 50% / auto 18px $card-links-hover-bg;
      }
    }

  
  /* flickr */
    .flickr a,
    &.flickr a,
    a[href*="flickr.com"],
    a[href^="https://www.flickr.com"],
    a[href^="http://www.flickr.com"],
    a[href^="https://flickr.com"],
    a[href^="http://flickr.com"] {
      background:$ico-flickr-white no-repeat scroll 50% 50% / auto 10px $card-links-action-bg;
      
      &:hover {
        background: $ico-flickr-white no-repeat scroll 50% 50% / auto 10px $card-links-hover-bg;
      }
    }	
	}
}



// /* SOCIAL SHARE */
.soc-accelerators {
	h2 {
		background: $portal_secondary;
	}
	ul {
		li {
			a {
				background: white;
			}
		}	
	}
}