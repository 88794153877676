// LOAD MORE PAGER
.item-list ul.pager.pager-load-more {

	a {
		color: white;
		background:$portal_primary $arrow-button-down  no-repeat scroll 9px 18px / 55px auto;
		&:hover {
			color:white;
			background:$black_olive $arrow-button-down  no-repeat scroll 9px 18px / 55px auto;
		}
	}
}